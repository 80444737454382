export const colCustomerUsers = "customerUsers";
export const colCustomers = "customers";
export const colFcmTokens = "fcmTokens";
export const colFieldFindingCategories = "fieldFindingCategories";
export const colTemuanChats = "temuanChats";
export const colTasks = "tasks";
export const colTemuan = "temuan";
export const colNotification = "notification";
export const colKalibrasi = "kalibrasi";
export const colTangkiKimia = "tangkiKimia";
export const colPompa = "pompa";
export const colNtu = "ntu";
export const colReset = "reset";
export const colUsers = "users";
export const colSchedules = "schedules";
export const colShifts = "shifts";
export const colAttendances = "attendances";
export const colTaskReport = "taskReports";
export const colTaskStatus = "taskStatus";
export const colTaskComments = "taskComments";
export const colViewPemakaian = "viewPemakaian";
export const colViewMeter = "viewMeter";

// jangan diganti sudah sama dg android operator
export const OPR_INTAKE = 32;
export const OPR_WTP1 = 331;
export const OPR_WTP2 = 332;
export const OPR_WTP3 = 333;
export const KABAG_PERENCANAAN = 16;
export const TEKNISI = 30;
export const OPR_DISTRIBUSI = 34;
export const KABAG_PEMELIHARAAN_LAB = 17;
export const OPR_QC = 31; // `analis`
export const KABAG_SALES = 10;

export const pressureDistribusiIds = [
  "R12220302104110",
  "R16220302103150",
  "R17220302102538",
  "R10220301182253",
  "R13220302103755",
  "R11220302102328",
  "R18220302103959",
  "R16220302103315",
  "R18220302102146",
  "R15220302103028",
  "R19220302102914",
  "R17220302102701",
  "R21220302103603",
  "R19220302103705",
];

export const wtp1BakuId = "PoieD3yxn4avq9SrEp18";
export const wtp1BakuErrorId = "16383476271822";
export const wtp1Cikande = "g7cSe37ROXCVtVyopNyJ"; // produksi wtp1

export const wtp3BakuId = "n2adtBK2C8hoGio0RJnu";
export const debitBakuId = "16340271556862";
export const wpt3BakuErrorId = "16340275968843";

export const wtp3NtuId = "R16220206120451";
export const wtp3NtuDetailId = "16441238913040";
export const wtp3PpmDetailId = "16441238913041";

export const wtp3Industri = "TJFeQKrXcHNtSoTcahfg";
export const wtp3IndustriError = "16340276134633";
export const wtp3Carenang = "JOFQowzTotL69WsY12W5";
export const wtp3CarenangError = "16340282393692";
export const wtp3Cijeruk = "yUe74oUfCtWXiK4HU5BK";
export const wtp3CijerukError = "16340283218212";

export const fieldFindingCategories = [
  {
    id: 0,
    name: "Semua",
    description: "",
    departementIds: [0],
  },
  {
    id: 1,
    name: "Kendala Kualitas Air",
    description:
      "Kategori kendala kualitas air apabila terdapat air bau, air keruh, dan kendala lain terkait kualitas air.",
    departementIds: [KABAG_PEMELIHARAAN_LAB, OPR_QC],
  },
  {
    id: 2,
    name: "Kendala Teknis",
    description:
      "Kategori kendala teknis apabila ada kebocoran, air kecil, air bocor, dll.",
    departementIds: [OPR_DISTRIBUSI],
  },
  {
    id: 3,
    name: "Kendala Tagihan",
    description: "Kategori kendala tagihan apabila terdapat kesalahan tagihan.",
    departementIds: [KABAG_SALES],
  },
  {
    id: 4,
    name: "Kendala Lainnya",
    description: "Kategori kendala lainnya.",
    departementIds: [KABAG_SALES],
  },
];

export const statusNonRutin: any = [
  { key: 0, value: "Baru" },
  { key: 1, value: "Dikerjakan" },
  { key: 2, value: "Selesai" },
  { key: -1, value: "Pending" },
];

export const jamShift = [
  {
    id: "RvH3NS2MCGYq3D4kTXnN",
    name: "Shift 1",
    hours: [0, 1, 2, 3, 4, 5, 6, 7],
  },
  {
    id: "VhdorDTIsMBIsbPE5XvD",
    name: "Shift 2",
    hours: [8, 9, 10, 11, 12, 13, 14, 15],
  },
  {
    id: "ab77dfnJOCI6LK70NG70",
    name: "Shift 3",
    hours: [16, 17, 18, 19, 20, 21, 22, 23],
  },
];
export const deptHirarcyOperator = [
  {
    parent: 16, // kabag op perencanaan
    childs: [21, 22, 31, 32, 33],
  },
  {
    parent: 17, // kabag teknisi
    childs: [30, 23, 24],
  },
  {
    parent: 10, // kabag sales / distribusi
    childs: [34],
  },
];
export const OPERATORS = [
  {
    key: OPR_INTAKE,
    value: "Operator Intake",
  },
  {
    key: OPR_WTP1,
    value: "Operator WTP 1",
  },
  {
    key: OPR_WTP2,
    value: "Operator WTP 2",
  },
  {
    key: OPR_WTP3,
    value: "Operator WTP 3",
  },
  {
    key: OPR_DISTRIBUSI,
    value: "Operator Distribusi",
  },
  {
    key: KABAG_PEMELIHARAAN_LAB,
    value: "Kabag Pemeliharaan  & Lab",
  },
  {
    key: KABAG_PERENCANAAN,
    value: "Kabag Perencanaan & Digitalization",
  },
  {
    key: KABAG_SALES,
    value: "Kabag Sales",
  },
  {
    key: OPR_QC,
    value: "Quality Control",
  },
];

export const namaBulan = [
  {
    key: 1,
    abbr: "jan",
    value: "Januari",
  },
  {
    key: 2,
    abbr: "feb",
    value: "Februari",
  },
  {
    key: 3,
    abbr: "mar",
    value: "Maret",
  },
  {
    key: 4,
    abbr: "apr",
    value: "April",
  },
  {
    key: 5,
    abbr: "mei",
    value: "Mei",
  },
  {
    key: 6,
    abbr: "jun",
    value: "Juni",
  },
  {
    key: 7,
    abbr: "jul",
    value: "Juli",
  },
  {
    key: 8,
    abbr: "agu",
    value: "Agustus",
  },
  {
    key: 9,
    abbr: "sep",
    value: "September",
  },
  {
    key: 10,
    abbr: "okt",
    value: "Oktober",
  },
  {
    key: 11,
    abbr: "nov",
    value: "November",
  },
  {
    key: 12,
    abbr: "des",
    value: "Desember",
  },
];
